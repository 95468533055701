<template>
  <div>
    <b-card no-body class="mb-2">
      <div>
        <filter-slot
          :filter="visibleFilters"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reset-all-filters="resetAllFilters()"
          @reload="$refs.clientsList.refresh()"
        >
          <template #custom-vselect>
            <FilterStatusAccount :filters="filter[4]" />
          </template>
          <template #table>
            <b-table
              ref="clientsList"
              slot="table"
              :busy.sync="isBusy"
              :fields="visibleFields"
              :items="search"
              :filter="visibleFilters"
              :per-page="paginate.perPage"
              :current-page="paginate.currentPage"
              no-provider-filtering
              responsive="sm"
              small
              show-empty
              sticky-header="65vh"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(lead_name)="data">
                <div
                  class="d-flex flex-column justify-content-center align-items-start"
                >
                  <p class="mb-0 font-weight-bold text-info">
                    {{ data.item.lead_name }}
                  </p>
                  <span v-if="data.item.user_responsible" class="text-warning">
                    <tabler-icon
                      :icon="
                        data.item.user_responsible === currentUser.user_id
                          ? 'StarIcon'
                          : 'LockIcon'
                      "
                      size="15"
                    />
                    <strong>CEO's client</strong>
                  </span>
                  <p class="mb-0">
                    {{ data.item.mobile }}
                  </p>
                  <b-badge v-if="data.item.claims != 0" variant="light-info">
                    IN CLAIM ({{ data.item.claims }})
                  </b-badge>
                </div>
              </template>
              <template #cell(account)="data">
                <div
                  class="d-flex flex-column justify-content-center align-items-start"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    style="padding-bottom: 4px"
                  >
                    <router-link
                      class
                      target="_blank"
                      :to="`/quality/clients/account/${account.account_id}`"
                      >{{ account.account }}</router-link
                    >
                  </span>
                </div>
              </template>
              <template #cell(status)="data">
                <div
                  class="d-flex flex-column justify-content-center align-items-start"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    class="d-flex justify-content-between align-items-center"
                    style="padding-bottom: 4px"
                  >
                    <status-account
                      :account="account"
                      :text="true"
                    ></status-account>
                  </span>
                </div>
              </template>
              <template #cell(fee_charges)="data">
                <div
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  class="d-flex flex-column justify-content-center align-items-start"
                  style="padding-bottom: 4px"
                >
                  {{ account.charges | formatMoney }}
                </div>
              </template>
              <template #cell(mp)="data">
                <div
                  v-for="(account, index) in data.item.accounts"
                  :key="index"
                  class="d-flex flex-column justify-content-start align-items-start"
                  style="padding-bottom: 4px"
                >
                  {{ account.monthly_amount | formatMoney }}
                </div>
              </template>
              <template #cell(lp)="data">
                <div
                  class="d-flex flex-column justify-content-start align-items-start"
                  style="position: relative"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    style="display: flex"
                  >
                    <b-img
                      v-if="account.state_lp == 1"
                      :src="`${baseImg}/assets/images/paragon/last_payment/lastpayment-out.ico`"
                      style="
                        margin-right: 5px;
                        height: 15px;
                        margin-top: 2px;
                        width: 15px;
                      "
                    />
                    <b-img
                      v-else
                      :src="`${baseImg}/assets/images/paragon/last_payment/lastpayment-in.ico`"
                      style="
                        margin-right: 5px;
                        height: 15px;
                        margin-top: 2px;
                        width: 15px;
                      "
                    />
                    {{
                      account.last_payment == "-" ? "" : account.last_payment
                    }}
                  </span>
                </div>
              </template>
              <template #cell(pt)="data">
                <div>
                  <ul style="padding-left: 0px; margin-bottom: 0px">
                    <li
                      v-for="(account, index) in data.item.accounts"
                      :key="index"
                      style="list-style: none"
                    >
                      <status-payment :account="account" />
                    </li>
                  </ul>
                </div>
              </template>
              <template #cell(advisor)="data">
                <div
                  class="d-flex flex-column justify-content-start align-items-start"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    style="padding-bottom: 4px"
                  >
                    <feather-icon
                      v-if="account.state_advisor === 1"
                      icon="CircleIcon"
                      size="13"
                      :style="`color: #00CC00; border-color: #00CC00; background: #00CC00; border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                    />
                    <feather-icon
                      v-if="account.state_advisor === 2"
                      icon="CircleIcon"
                      size="13"
                      :style="`color: #00CC00; border-color: #00CC00; background: #00CC00; border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                    />
                    <feather-icon
                      v-if="account.state_advisor === 0"
                      icon="CircleIcon"
                      size="13"
                      :style="`color: rgb(204, 204, 204); border-color: #00CC00; background: rgb(204, 204, 204); border-radius: 50%; margin-bottom: 2px; margin-right: 5px;`"
                    />
                    <span>{{ account.advisor_name }}</span></span
                  >
                </div>
              </template>
              <template #cell(ln)="data">
                <div
                  class="d-flex flex-column justify-content-start align-items-start"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    :style="
                      account.s_note <= 1
                        ? isDarkSkin
                          ? 'color:#fff'
                          : 'color:#6d6b7b'
                        : account.s_note <= 2
                        ? 'color: #FF8000'
                        : 'color:#FF0000'
                    "
                    style="padding-bottom: 4px"
                    >{{ account.last_note }}
                  </span>
                </div>
              </template>
              <template #cell(ut)="data">
                <div
                  class="d-flex flex-column justify-content-start align-items-start"
                  style="margin-left: 10px"
                >
                  <span
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    :style="
                      account.task <= 5
                        ? 'color:#000000; position: relative;'
                        : account.task <= 10
                        ? 'color: #FF8000; position: relative;'
                        : 'color:#FF0000; position: relative;'
                    "
                  >
                    <b-img
                      :src="`${baseImg}/assets/images/paragon/tasks/uncompletetasks.ico`"
                      style="
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                      "
                    />
                    <span
                      style="font-size: 11px"
                      :style="isDarkSkin ? 'color: #fff' : ''"
                      >{{ account.task }}</span
                    >
                  </span>
                </div>
              </template>
              <template #cell(creation_date)="data">
                <ul style="padding-left: 0px; margin-bottom: 0px">
                  <li
                    v-for="(account, index) in data.item.accounts"
                    :key="index"
                    style="list-style: none"
                  >
                    {{ account.created_at | myGlobal }}
                  </li>
                </ul>
              </template>
              <template
                v-if="
                  currentUser.role_id == 1 ||
                  currentUser.role_id == 2 ||
                  currentUser.role_id == 3
                "
                #cell(services)="data"
              >
                <b-dropdown
                  v-if="
                    data.item.user_responsible == currentUser.user_id ||
                    data.item.user_responsible == null
                  "
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                  boundary="viewport"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <!-- Add Service -->
                  <b-dropdown-item
                    variant="warning"
                    @click="modalAddService(data.item.accounts[0])"
                  >
                    <feather-icon icon="FilePlusIcon" />
                    <span class="align-middle ml-50">Add service</span>
                  </b-dropdown-item>

                  <!-- Change Service -->
                  <b-dropdown-item
                    :variant="
                      $route.matched[0].meta.module == 26
                        ? 'warning'
                        : 'success'
                    "
                    @click="modalChangeService(data.item.accounts[0])"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Change service</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </template>
        </filter-slot>
      </div>
    </b-card>

    <!-- Modal Add Services -->
    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="modalOpenAddService = false"
      @changeProgram="openModalProgram"
    />
  </div>
</template>

<script>
import dataFields from "@/views/quality/views/clients/components/fields.data";
import dataFilters from "@/views/quality/views/clients/components/filters.data";
import ClientService from "@/views/quality/views/clients/services/client.service.js";
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";
import { mapGetters, mapActions } from "vuex";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import ModalAddService from "@/views/commons/components/clients/modals/ModalAddService.vue";

export default {
  components: {
    StatusPayment,
    StatusAccount,
    NcrLeadsService,
    FilterStatusAccount,
    ModalAddService,
  },
  data() {
    return {
      filter: dataFilters,
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "account_id",
      startPage: null,
      toPage: null,
      isBusy: false,
      items: [],
      fields: dataFields,
      order: "desc",
      usersServices: [],
      programsAll: [],

      typeAddChange: null,
      detailsClient: {},
      typeModal: null,
      modalOpenAddService: false,
      event: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      this.fields[8].visible =
        this.currentUser.role_id !== 3 || this.moduleId === 2;
      this.fields[9].visible =
        (this.moduleId !== 2 && this.moduleId !== 4 && this.moduleId !== 6) ||
        (this.moduleId === 6 && this.currentUser.role_id !== 4);
      return this.fields.filter((column) => column.visible);
    },
    visibleFilters() {
      this.filter[2].visible = true;

      if (this.filter[2].model != null) {
        this.filter[3].visible = true;
        this.usersAllPrograms();
      } else {
        this.filter[3].visible = false;
      }

      return this.filter.filter((column) => column.visible);
    },
    paymentType: {
      get() {
        return this.filter[5].model;
      },
      set(value) {
        this.filter[5].model = value;
      },
    },
    paymentDay: {
      get() {
        return this.filter[6].model;
      },
      set(value) {
        this.filter[6].visible = value;
      },
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    program() {
      return this.filter[2].model;
    },
    advisorId() {
      return this.filter[3].model;
    },
  },
  watch: {
    paymentType(newVal) {
      if (newVal === 1) {
        this.paymentDay = true;
      } else {
        this.paymentDay = false;
        this.filter[6].model = 0;
      }
    },
    program(newValue) {
      if (!newValue) this.filter[2].model = 0;
    },
    advisorId(newValue) {
      if (!newValue) this.filter[3].model = 0;
    },
  },
  created() {
    this.allPrograms();
    this.getAllStatusClientAccount();
  },
  methods: {
    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),

    async search(ctx) {
      try {
        let orderBy = 5;
        let sortDirection = "desc";
        if (ctx.sortBy === "lead_name") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 2;
        } else if (ctx.sortBy === "account") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 4;
        } else if (ctx.sortBy === "fee_charges") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 42;
        } else if (ctx.sortBy === "pt") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 48;
        } else if (ctx.sortBy === "mp") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 43;
        } else if (ctx.sortBy === "creation_date") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
          orderBy = 5;
        }
        const params = {
          per_page: this.paginate.perPage,
          orderby: orderBy,
          order: sortDirection,
          from: this.filter[0].model,
          to: this.filter[1].model,
          text: this.filterPrincipal.model,
          state: this.state,
          status: this.filter[4].model,
          advisor: this.filter[3].model,
          type: this.paymentType,
          day: this.paymentDay,
          rol_id: 1,
          program: this.filter[2].model,
          list: this.$route.meta.list_table,
          showall: true,
          typeTab: "test",
        };
        const { data } = await ClientService.getClientsQuality(
          params,
          ctx.currentPage
        );
        const items = data.data.map((item) => ({
          ...item,
          accounts: JSON.parse(item.accounts),
        }));

        // Must return an array of items or an empty array if an error occurred
        this.items = items;
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalData = data.total;
        this.totalRows = data.total;
        this.toPage = data.to;

        return this.items;
      } catch (error) {
        this.showErrorSwal(error);
        return [];
      }
    },
    resetAllFilters() {
      this.filter.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.$refs.clientsList.refresh();
    },
    async usersAllPrograms() {
      try {
        const params = {
          idmodule: this.convertProgramToModule(this.program),
          iduser: this.currentUser.user_id,
          idrole: this.currentUser.role_id,
        };

        const data = await ClientService.usersPrograms(params);
        this.filter[3].options = data;
        this.filter[3].options.unshift({ id: 0, user_name: "All" });
        this.filter[3].model = 0;
      } catch (error) {
        console.log(error);
      }
    },

    async allPrograms() {
      try {
        const { data } = await ClientService.programs();
        const items = data.map((item) => ({ id: item.id, value: item.value }));
        this.filter[2].options = items;
        this.filter[2].options.unshift({ id: 0, value: "All" });
        this.filter[2].model = 0;
      } catch (error) {
        console.log(error);
      }
    },
    async getAllStatusClientAccount() {
      this.filter[4].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount();
      this.filter[4].options.push({
        value: 0,
        label: "All",
        icon: "CircleIcon",
      });
      this.filter[4].options.push(...data);
    },

    async modalChangeService(data) {
      const result = await this.showConfirmSwal("Are you sure change service?");
      if (result.value) {
        this.typeAddChange = 2;
        await this.createEvent(data);
        this.modalGeneralService(data, 4);
      }
    },

    async modalAddService(data) {
      const result = await this.showConfirmSwal(
        "Are sure want to add new service?"
      );
      if (result.value) {
        this.typeAddChange = 1;
        this.modalGeneralService(data, 3);
        await this.createEvent(data);
      }
    },

    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.detailsClient.accounts = [
        {
          program: data.program,
        },
      ];
      this.typeModal = type;
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },

    async createEvent(data) {
      const response = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: data.lead_id,
      });
      this.event = response.id;
    },

    async openModalProgram(data) {
      if (data.captured.id && data.program.id && data.seller.id) {
        const response = await this.showConfirmSwal(
          "Are you sure?",
          "You won't be able to revert this!"
        );
        if (response.isConfirmed) {
          await this.addPreloader();
          const param = {
            prices: null,
            observation: "Service",
            contract: 1,
            program: data.program.id,
            fee: null,
            suggested: null,
            otherpricesp: null,
            event: this.event,
            json_noce: null,
            stateid: 0,

            // Diferents to add change Services
            account: this.detailsClient.account_id,
            captured: data.captured.id,
            seller: data.seller.id,
            type: this.typeAddChange,
            user_id: this.currentUser.user_id,
            module: this.moduleId,
            id_score: null,
            json_ce: null,
            type_client: "CLIENT",
          };
          try {
            const result = await LeadService.insertSaleMade(param);
            if (result.status == 200) {
              this.modalOpenAddService = false;
              this.$router
                .push({ name: "sales-made-quality-lead" })
                .catch((err) => {
                  console.log(err);
                });
              this.removePreloader();
            }
          } catch (error) {
            this.showErrorSwal(
              "Please, verify if the client already has the service, otherwise contact support.",
              "Error"
            );
          } finally {
            this.removePreloader();
          }
        }
      }
    },
  },
};
</script>
